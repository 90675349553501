<template>
    <div class="content">
        <div class="search">
          <el-button type="primary" class="el-icon-phone-outline" @click='visitorReserve' style="margin-right: 10px;"> 预约</el-button>
          <JsonExcel :fields="exportDataStandard" :data="exportData" type="xls" name="访客预约" :fetch="createExportData" :before-finish="finishDownload" worksheet="访客预约">
              <el-button type="primary" class="el-icon-download"> 导出</el-button>
          </JsonExcel>
          <el-button type="primary" class="el-icon-upload2" @click='refreshUpload' style="margin-left: 10px;"> 重新上传</el-button>
        </div>
        <el-card style="margin-top:15px;width:100%" class="box-card">
          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <div class="search">
                 <label>预约时间：</label>
                 <el-date-picker v-model="searchForm.time" type="datetimerange" range-separator="-" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <div class="search">
                  <label>车牌号：</label>
                  <el-input v-model="searchForm.plateno" class="marg" style="width:150px"></el-input>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
                <div class="search">
                  <label>状态：</label>
                  <el-select v-model="searchForm.opt_type" style="width:150px" @change="parkChanged">
                    <el-option label="全部" :value="0"></el-option>
                    <el-option label="正常" :value="1"></el-option>
                    <el-option label="已完成" :value="2"></el-option>
                    <el-option label="已取消" :value="3"></el-option>
                  </el-select>
                </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="6">
              <div v-if="User.user_type!==5" class="search">
                <label>车场：</label>
                <el-select v-model="searchForm.pk_id" style="width:150px" @change="parkChanged">
                  <el-option v-if="User.user_type===5" label="全部" :value="0"></el-option>
                  <el-option v-for="item in ParkDataList" :key="item.pk_id" :label="item.pk_name" :value="item.pk_id"></el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :xs="24" :sm="12" :md="12" :lg="4">
              <div class="search">
                <el-button type="primary" plain @click="Search">查询</el-button>
              </div>
            </el-col>
          </el-row>
        </el-card>

          <el-table :data='DataList' border style="width: 100%;font-size:13px" max-height='calc(100vh - 250px)' height='calc(100vh - 250px)' stripe :highlight-current-row='true' size="mini">
            <el-table-column v-if="User.user_type!==5" align="center" prop="park_name" label="所属车场"></el-table-column>
            <el-table-column align="center" prop="log_plateno" label="车牌号" min-width="145px"></el-table-column>
            <el-table-column align="center" prop="log_visitor_name" label="访客" min-width="145px"></el-table-column>
            <el-table-column align="center" prop="log_opt_time" label="操作时间" min-width="145px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_opt_time)}}
                </template>
            </el-table-column>
            <el-table-column align="center" label="预约入场时段" min-width="200px">
                <template slot-scope="scope">
                  {{FormData(scope.row.log_reserve_begintime)+" 至 "+FormData(scope.row.log_reserve_endtime)}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="log_visitor_phone" label="联系方式" min-width="145px"></el-table-column>
            <el-table-column align="center" prop="log_remark" label="备注"></el-table-column>
            <el-table-column align="center" prop="log_limit_count" label="次数限制">
                <template slot-scope="scope">
                    {{scope.row.log_limit_count===0?"不限次":(scope.row.log_limit_count+"次")}}
                </template>
            </el-table-column>
            <el-table-column align="center" prop="log_state" label="状态">
                <template slot-scope="scope">
                    <el-tag :type="scope.row.log_state === 0 ? 'success' : (scope.row.log_state === 1?'warning':'danger')" disable-transitions>{{scope.row.log_state===0?"正常":(scope.row.log_state===1?"已完成":"已取消")}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                  <el-link v-if="scope.row.log_state===0" type="primary" @click="Edit(scope.row)">修改</el-link>
                  <el-link v-if="scope.row.log_state===0" type="danger" @click="CancelReserve(scope.row)">取消</el-link>
                </template>
            </el-table-column>
          </el-table>
          <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @size-change="handleSizeChange" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize"></el-pagination>
  
        <el-dialog title="访客预约" :visible.sync="dialogVisible" width="500px" :append-to-body='true'>
          <el-form :model="visitorForm" :rules="rules" ref="visitorForm" label-width="100px" size='medium'>
              <el-form-item label="访客名称">
                  <el-input :disabled="isEdit" v-model="visitorForm.log_visitor_name"></el-input>
              </el-form-item>
              <el-form-item label="联系方式">
                <el-input :disabled="isEdit" v-model="visitorForm.log_visitor_phone"></el-input>
              </el-form-item>
              <el-form-item label="车牌号" prop="log_plateno">
                <el-input :disabled="isEdit" v-model="visitorForm.log_plateno"></el-input>
              </el-form-item>
              <el-form-item label="预约起始日期">
                <el-date-picker :disabled="isEdit" v-model="visitorForm.log_reserve_begintime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item label="截止日期">
                <el-date-picker v-model="visitorForm.log_reserve_endtime" type="datetime" format="yyyy-MM-dd HH:mm:ss" value-format="yyyy-MM-dd HH:mm:ss" style="width: 100%;"></el-date-picker>
              </el-form-item>
              <el-form-item label="进出次数限制">
                <el-tooltip class="item" effect="dark" content="为0表示不限次" placement="bottom-start">
                  <el-input-number v-model="visitorForm.log_limit_count" controls-position="right" :min="0" style="width: 100%;"></el-input-number>
                </el-tooltip>
              </el-form-item>
              <el-form-item label="备注">
                <el-input v-model="visitorForm.log_remark"></el-input>
              </el-form-item>
          </el-form>
          <span slot="footer" class="dialog-footer">
              <el-button @click="dialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="submit">确 定</el-button>
          </span>
        </el-dialog>

    </div>
</template>

<script>
import util from '../../../util/util'
import JsonExcel from 'vue-json-excel'

export default{
    data:function(){
        return{
          User:null,
            searchForm:{
              pk_id:0,
                pageNo:1,
                pageSize:15,
                total:0,
                time:[],
                plateno:'',
                opt_type:0
            },
          
            isEdit:false,
            dialogVisible:false,
            visitorForm:{
              log_id:0,
              log_plateno:'',
              log_reserve_begintime:'',
              log_reserve_endtime:'',
              log_visitor_name:'',
              log_visitor_phone:'',
              log_remark:'',
              log_limit_count:0
            },


            DataList:[],
            ParkDataList:[],

            exportDataStandard: {
              所属车场: "park_name" ,
              车牌号: "log_plateno" ,
              访客: "log_visitor_name" ,
              操作时间: {
                field: 'log_opt_time',
                callback: value => {
                  return this.FormData(value)
                }
              },
              预约入场起始时间: {
                field: 'log_reserve_begintime',
                callback: value => {
                  return this.FormData(value)
                }
              },
              预约入场截止时间: {
                field: 'log_reserve_endtime',
                callback: value => {
                  return this.FormData(value)
                }
              },
              预约次数限制: {
                field: 'log_limit_count',
                callback: value => {
                  return value===0?"不限次":(value+'次')
                }
              },
              联系方式: "log_visitor_phone" ,
              备注: "log_remark" ,
              状态:{
                field: 'log_state',
                callback: value => {
                  return value===0?"正常":'已完成'
                }
              },
            },
            exportData: [],

            rules: {
              log_plateno: [{ required: true, message: '请输入车牌号', trigger: 'blur' }],
            },
        }
    },
    computed:{
        FormData () {
          return function (time) {
            if(time===null||time===undefined||time==='')
              return ''
            else
              return util.FormateDate(time, 2)
          }
        },
    },
    components: {
      JsonExcel
    },
    created(){
        this.User = util.JesToken()
        this.getParkDataList()
    },
    methods:{
        getParkDataList(){
        if(this.User.user_type===5){
            this.GetDataList()
        }
        else{
          util.Get('parkmanage/getparkdatalist').then(res => {
            if (res.rpStatus === 10000) {
               this.ParkDataList = res.list
               if(res.list.length>0){
                 this.searchForm.pk_id=res.list[0].pk_id
                 this.GetDataList()
               }
            }
          })
        }
      },
        parkChanged(value){
            this.searchForm.pk_id=value
            this.GetDataList()
        },
        GetDataList(){
            this.searchForm.begintime=''
            this.searchForm.endtime=''
            if(this.searchForm.time!=undefined&&this.searchForm.time!==null&&this.searchForm.time.length>0){
                this.searchForm.begintime=this.searchForm.time[0]
                this.searchForm.endtime=this.searchForm.time[1]
            }
            util.Get('logreserve/getlogdataList',this.searchForm).then(res=>{
                if(res.rpStatus===10000){
                    this.searchForm.total=res.total
                    res.list.forEach(element => {
                        element.picdata = []
                    })
                    this.DataList=res.list
                   
                }
            })
        },
        current_change(page){
            this.searchForm.pageNo=page
            this.GetDataList()
        },
        handleSizeChange(pageSize){
            this.searchForm.pageSize=pageSize
            this.GetDataList()
        },
        Search(){
          if(util.CheckUserAuth('4-7-1')){
            this.GetDataList()
          }
        },
        refreshUpload(){
          util.Send('refresh_upload_logreserve',this.searchForm.pk_id)
        },
        // 导出
        async createExportData () {
          if(util.CheckUserAuth('4-7-2')){
            this.searchForm.pageNo = 0
            var form = JSON.parse(JSON.stringify(this.searchForm))
            form.pageNo = 0

            var result = await util.Get('logreserve/getlogdataList', form)
            if (result.rpStatus === 10000) {
              this.exportData = result.list
              return this.exportData
            }
          }
        },
        finishDownload () {
          this.$message({
            message: '导出数据成功',
            type: 'success'
          })
          this.exportData=[]
        },

        visitorReserve(){
          this.isEdit=false
          this.dialogVisible=true
          this.visitorForm.log_plateno=''
          this.visitorForm.log_reserve_begintime=util.FormateDate(new Date(),2)
          let date=new Date()
          date= date.setTime(date.getTime()+24*3600*1000)
          this.visitorForm.log_reserve_endtime=util.FormateDate(date,2)
          this.visitorForm.log_visitor_name=''
          this.visitorForm.log_visitor_phone=''
          this.visitorForm.log_remark=''
          this.visitorForm.log_limit_count=1
        },
        //预约
        submit(){
          if(util.CheckUserAuth('4-7-3')){
            if(!this.visitorForm.log_reserve_begintime){
              this.$message.error('请输入预约时间')
              return
            }
            if(!this.visitorForm.log_reserve_endtime){
              this.$message.error('请输入预约时间')
              return
            }
            let t1=new Date(this.visitorForm.log_reserve_begintime)
            let t2=new Date(this.visitorForm.log_reserve_endtime)
            if(t2<t1){
              this.$message.error('预约截止日期禁止小于起始日期')
              return
            }

            this.$refs.visitorForm.validate((valid) => {
              if (valid) {
                util.Post('LogReserve/VisitorReserve', this.visitorForm).then(res => {
                  if (res.rpStatus === 10000) {
                    this.GetDataList()
                    this.dialogVisible = false
                    this.$message.success('预约成功')
                  } else { this.$message.error(res.rpMsg) }
                })
              }
            })
          }
        },
        Edit(row){
          if(util.CheckUserAuth('4-7-4')){
            this.isEdit=true
            this.dialogVisible=true
            this.visitorForm=JSON.parse(JSON.stringify(row))
          }
        },
        CancelReserve(row){
          if(util.CheckUserAuth('4-7-5')){
            this.$confirm('确定取消车牌“' + row.log_plateno + '”的预约？', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              util.Get('LogReserve/CancelReserve?id='+row.log_id).then(res => {
                if (res.rpStatus === 10000) {
                  this.GetDataList()
                  this.$message.success('取消预约成功')
                } else { this.$message.error(res.rpMsg) }
              })
            }).catch(e => e)
          }
        }
    }
}
</script>

<style scoped>
  .content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.el-table{
        margin-top: 15px;
    }
.search{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 13px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
.search label{
  margin-right: 8px;
  width: 90px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
::v-deep.el-table thead {
  color: #666666;
  font-weight: 500;
}
</style>